import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { mobile } from "../responsive";
import Title from "./../components/Title";
import SubCategories from "../components/SubCategories";
import { useLocation, useParams } from "react-router-dom";
// import Products from "../components/Products";
import Products from "./Products/Products";
import Footer from "./../components/Footer";
import apiClient from "../api/client";


const CategoryPage = ({ item }) => {
  //item should contain category info like category name etc
  const [cat, setCat] = useState();
  const { state } = useLocation();
  let { id } = useParams()
  const categoryId = state?._id ? state?._id : id
   
  
  useEffect(() => {
   
    fetchCatById()
  }, [categoryId]);


  const fetchCatById = async () => {
    const { data } = await apiClient.get("/variations/getcatbyid", {catId : categoryId});
   
    setCat(data);
  };

  return (
    <>
      <Container>
        <BannerWrapper>
          <Title title={cat?.name} />
          <Banner src={cat?.banner} />
        </BannerWrapper>
        <Title title="Sub Categories" />
        {/* get sub categories by categories */}
        <SubCategories data={categoryId} title="subcategory" />
        <Title title="Special Categories" />
        {/* get special categories by categories */}
        <SubCategories data={categoryId} title="specialcategory" />

        {/* Products list with filters */}
        <div key={categoryId}>
          <Products data={categoryId} />
        </div>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  margin-top: 150px;
`;

const Heading1 = styled.h1`
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 0;
  ${mobile({ fontSize: "24px", textAlign: "center" })}
`;

const Banner = styled.img`
  height: 60vh;
  width: 90vw;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border-radius: 7px;
  ${mobile({ height: "20vh", objectFit: "contain" })}
`;

const BannerWrapper = styled.div`
  position: relative;
`;

export default CategoryPage;
