import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Title from "./../components/Title";
import { useLocation, useParams } from "react-router-dom";

import Footer from "./../components/Footer";
import SpecialProducts from "./Products/SpecialProducts";
import apiClient from "../api/client";


const SpecialCategoryPage = ({ item }) => {
  const [cat, setCat] = useState();
  const { state } = useLocation();
  let { id } = useParams()
  const categoryId = state?._id ? state?._id : id;
  useEffect(() => {
   
    fetchCatById()
  }, [categoryId]);
  const fetchCatById = async () => {
    const { data } = await apiClient.get("/variations/getspecialcatbyid", {catId : categoryId});
   
    setCat(data);
  };
  return (
    <>
      <Navbar />
      <Container>
        <Title title={cat?.name} />
        {/* <SubCategories data={categoryId} title="specialcategory" /> */}
        <SpecialProducts data={categoryId} />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  margin-top: 150px;
`;

export default SpecialCategoryPage;
