import React from "react";
import Helmet from "react-helmet";

const MetaData = ({ title, image }) => {
 
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="www.fiorapets.qa" />
      <meta property="og:title" content={title} />
      

      <meta name="og:image" content={image?.img} />
    </Helmet>
  );
};

export default MetaData;
