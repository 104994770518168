import React from "react";
import Footer from "../../components/Footer";
import "./AboutUs.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        marginTop: "170px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Privacy policy
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "15px" }}>
          All packaging reflects the same distinctive styling and quality as the
          item that you have selected. With respect to shipments of merchandise
          to consumers, wherever they may reside, title to the merchandise shall
          pass to the buyer upon delivery of the merchandise to the common
          carrier. Nothing in this site grants to you any license or right to
          use any Trademark displayed on this site without the prior written
          permission of the owner of the Trademark. Your use or misuse of any
          Trademark displayed on this site, or of any other content on this
          site, is strictly prohibited. If a pricing error were to occur there
          was no attempt to unlawfully harm any customer. If an error is made we
          will try to correct it as quickly as possible. According to common law
          advertising when an order is requested online it is not a binding
          agreement according to the court system. It is never our intent to
          advertise with incorrect pricing. Unfortunately mistakes can and do
          happen. Until an order is shipped a contract has not been entered
          into. Data about the activity of visitors to this site is
          automatically provided to Fiorapets when you sign on. It is used by us
          for internal review in aggregate and non-personally-identifiable form.
          E-mail addresses and other personally identifying data about you is
          known to Fiorapets only when voluntarily submitted or posted to us. In
          addition, we sometimes use E-mail addresses and other personally
          identifying information to contact visitors who communicate with us we
          also use the shipping addresses of our customers to send them our
          catalogs which we feel will be of interest to them.
          <br />
          <br />
          <h4>MARKETING MATERIAL POLICY</h4>
          <p>
            At Fiora Pets, we take your privacy and data protection seriously.
            This policy outlines how we handle customer data for marketing
            purposes, specifically in the context of WhatsApp broadcasting, and
            our commitment to respecting your preferences and ensuring the
            security of your information. We may collect customer data that
            includes names, contact numbers, and other relevant information
            during the course of doing business and We use customer data for
            marketing and communication purposes, including sending WhatsApp
            broadcasts about our products, services, promotions, and important
            updates.
          </p>
          <br />
          <br />
          <h4>THIRD PARTY DATA SHARING POLICY</h4>
          <p>
            At Fiora Pets, we take your privacy and data protection seriously.
            This policy outlines how we handle customer data for marketing
            purposes, specifically in the context of WhatsApp broadcasting, and
            our commitment to respecting your preferences and ensuring the
            security of your information. We may collect customer data that
            includes names, contact numbers, and other relevant information
            during the course of doing business and We use customer data for
            marketing and communication purposes, including sending WhatsApp
            broadcasts about our products, services, promotions, and important
            updates.
          </p>
          <br />
          <br />
          <h4>DATA COLLECTION STORAGE POLICY</h4>
          <p>
            At Fiora Pets, we take data collection and storage seriously. This
            policy outlines our practices and commitment to securely collecting
            and storing data, including where and how it is stored.
            <li>
              Customer data is stored on secure, cloud-based servers provided by
              the Company.
            </li>
            <li>
              Backup data is stored in a secure off-site data center to ensure
              data redundancy and recovery.
            </li>
          </p>
          <br />
          <br />
          <h4>Cookie Usage Policy</h4>
          <p>
            As a Pet Store, we have intentionally chosen not to use cookies. We
            respect your privacy and have disabled cookies to ensure that we do
            not track your online activity or collect any personal data via
            cookies.
          </p>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
