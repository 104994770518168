import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import "./orderSuccess.css";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clear } from "../../redux/cartSlice";
import { orderclear } from "../../redux/orderSlice";
import apiClient from "../../api/client";
import Lottie from "react-lottie";
import animationData2 from "../../lottie/payment-processing.json";
import swal from "sweetalert";

const OrderFailed = () => {
  const [modal, setModal] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const items = useSelector((state) => state.order.order);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    status();
  }, []);

  const status = async () => {
    setModal(false)
  };

  return (
    <>
      {modal ? (
        <div
          style={{
            marginTop: "200px",
          }}
        >
          <Lottie options={defaultOptions2} height={200} width={200} />
          <h3 style={{ textAlign: "center" }}>
            Payment Processing... please wait
          </h3>
        </div>
      ) : (
        <div className="orderSuccess">
          <div>
            <CheckCircleIcon />
            <Typography>Payment Failed</Typography>
            <Link to="/myorders">View Orders</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderFailed;
