import React from "react";
import Footer from "../../components/Footer";
import "./AboutUs.css";

const ReturnPolicy = () => {
  return (
    <div
      style={{
        marginTop: "170px",
        
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Return policy
      </h2>
      <div className="aboutus-content" style={{ paddingBottom: "80px" }}>
      <p style={{ width: "100%", margin: "0 auto", paddingBottom: "5px" }}>
        We have a 3-day return policy, which means you have 3 days after
        receiving your item to request a return. To be eligible for a return,
        your item must be in the same condition that you received it, unworn or
        unused, with tags, and in its original packaging. You’ll also need the
        receipt or proof of purchase. To start a return, you can contact us at
        info@fiora.qa If your return is accepted, we’ll send you a return
        shipping label, as well as instructions on how and where to send your
        package. Items sent back to us without first requesting a return will
        not be accepted. You can always contact us for any return question at
        info@fiora.qa Damages and issues Please inspect your order upon
        reception and contact us immediately if the item is defective, damaged
        or if you receive the wrong item, so that we can evaluate the issue and
        make it right. Exceptions / non-returnable items Certain types of items
        cannot be returned, like perishable goods , custom products,
        and personal care goods . We also do not accept
        returns for hazardous materials, flammable liquids, or gases. Please get
        in touch if you have questions or concerns about your specific item.
        Unfortunately, we cannot accept returns on sale items or gift cards.
        Exchanges The fastest way to ensure you get what you want is to return
        the item you have, and once the return is accepted, make a separate
        purchase for the new item. Refunds We will notify you once we’ve
        received and inspected your return, and let you know if the refund was
        approved or not. If approved, you’ll be automatically refunded on your
        original payment method. Please remember it can take some time for your
        bank or credit card company to process and post the refund too.
      </p>
      </div>
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Cancellation Policy
      </h2>
      <div className="aboutus-content">
      <p style={{ width: "100%", margin: "0 auto", paddingBottom: "150px" }}>
        <li>  Cancellation Period: Any cancellation must be made within 24 hours/days before to be eligible for a
refund.</li>
        <li>Refund Policy: Cancellations made within the specified time frame will receive a full/partial refund or
credit. Refunds will be processed within 01 business days.</li>
        <li>Cancellation Procedure: Cancellations must be communicated via email, phone or online platform and
should include relevant billing details for verification purposes.</li>
    


      </p>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;
